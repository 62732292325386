//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api'

export default {
  props: {
    image: {
      type: Object,
      required: true
    },
    sizes: {
      type: String,
      default: 'xs:200vw md:200vw lg:2400px'
    },
    fit: {
      type: [Boolean, String],
      default: undefined
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  setup (props, { attrs }) {
    const optionalSizes = computed(() => {
      if (isNaN(attrs.width) && isNaN(attrs.height)) {
        return props.sizes
      }
      return undefined
    })

    const modifiers = computed(() => {
      if (props.fit) {
        const fill = props.fit === true ? 'transparent' : props.fit
        return { filters: { fill, format: 'png' } }
      }
      if (props.image.focus) {
        return {
          filters: {
            focal: props.image.focus
          }
        }
      }
      return {
        smart: true
      }
    })

    return {
      optionalSizes,
      modifiers
    }
  }
}
