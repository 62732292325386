//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed, ref } from '@nuxtjs/composition-api'
import useFadeInAnimation from '~/composables/useFadeInAnimation'

export default defineComponent({
  props: {
    image: {
      type: [String, Object],
      default: undefined
    },
    text: {
      type: Array,
      default: () => []
    },
    imagePosition: {
      type: String,
      default: 'left'
    },
    imageRatio: {
      type: String,
      default: '16:9'
    }
  },
  setup (props) {
    const imageRef = ref(null)
    const { itemVisible } = useFadeInAnimation(imageRef)

    const imageWidth = 500
    const imageHeight = computed(() => {
      switch (props.imageRatio) {
        case '1:1': return imageWidth
        case '2:3': return Math.ceil(imageWidth * 1.5)
        case '21:9': return Math.ceil(imageWidth * (9 / 21))
        default: return Math.ceil(imageWidth * (9 / 16))
      }
    })

    return {
      imageWidth,
      imageHeight,
      imageRef,
      itemVisible
    }
  }
})
