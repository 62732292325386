//
//
//
//
//
//
//
//
//
//

// https://lucide.dev/
// https://simpleicons.org/
import { defineComponent, computed } from '@nuxtjs/composition-api'
import { YouTubeIcon, FacebookIcon, InstagramIcon, XingIcon, LinkedInIcon, TwitterIcon, GoogleIcon, AdobeIcon } from 'vue-simple-icons'

export default defineComponent({
  // https://github.com/lucide-icons/lucide/tree/master/packages/lucide-vue#custom-props
  // https://github.com/mainvest/vue-simple-icons
  components: {
    YouTubeIcon, FacebookIcon, InstagramIcon, XingIcon, LinkedInIcon, TwitterIcon, GoogleIcon, AdobeIcon
  },
  props: {
    icon: {
      type: [String, Object],
      required: true
    },
    showAnimation: {
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  setup (props) {
    const iconComponent = computed(() => {
      if (props.icon?.plugin === 'rg-lucide-icons') {
        return `Icon${props.icon.icon}`
      } else if (props.icon?.plugin === 'b4t-icon-picker') {
        return props.icon.icon
      }

      return props.icon
    })

    return {
      iconComponent
    }
  }
})
