import { render, staticRenderFns } from "./Button.vue?vue&type=template&id=811fd5ec&scoped=true&"
import script from "./Button.vue?vue&type=script&lang=js&"
export * from "./Button.vue?vue&type=script&lang=js&"
import style0 from "./Button.vue?vue&type=style&index=0&id=811fd5ec&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "811fd5ec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/builds/reifen_gundlach/frontend/components/atoms/Icon/Icon.vue').default})
