//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api'
import useLoginInformation from '~/composables/useLoginInformation'

export default defineComponent({
  name: 'RegisterButton',
  setup () {
    const { routeToKcRegister, userIsLoggedIn } = useLoginInformation()
    return { routeToKcRegister, userIsLoggedIn }
  }
})
