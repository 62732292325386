//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, computed } from '@nuxtjs/composition-api'
import useExternalLink from '~/composables/useExternalLink'

export default defineComponent({
  name: 'LinkItem',
  props: {
    label: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: 'left' // left, right
    },
    whiteStyle: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showUnderline: {
      type: Boolean,
      default: true
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    boldStyle: {
      type: Boolean,
      default: true
    }
  },
  setup (props, element) {
    const { linkTarget, displayLink, isShopLink } = useExternalLink(props.link)

    const isDisabled = computed(() => {
      return props.disabled || (!props.link && !hasEvent.value)
    })

    const hasEvent = computed(() => !!element.listeners?.click)

    const textColor = computed(() => {
      if (isDisabled.value) {
        return 'var(--grey-300)'
      } else {
        return props.whiteStyle ? 'white' : 'var(--petrol)'
      }
    })

    const hoverColor = computed(() => {
      if (isDisabled.value) {
        return 'var(--grey-300)'
      } else {
        return props.whiteStyle ? 'var(--orange)' : 'var(--petrol-300)'
      }
    })

    const cssVars = computed(() => {
      return ({
        '--text-color': textColor.value,
        '--hover-color': hoverColor.value
      })
    })

    return {
      isDisabled,
      cssVars,
      linkTarget,
      displayLink,
      isShopLink
    }
  }
})
