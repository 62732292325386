import { ref, onMounted } from '@nuxtjs/composition-api'

const useFadeInAnimation = (itemRef) => {
  const itemVisible = ref(true)

  function onScroll () {
    if (!window || window.innerHeight - itemRef.value.$el.getBoundingClientRect().top > 0) {
      itemVisible.value = true
    } else {
      itemVisible.value = false
    }
  }

  onMounted(() => {
    onScroll()
    window.addEventListener('scroll', onScroll, { passive: true })
    window.addEventListener('resize', onScroll, { passive: true })
  })

  return {
    itemVisible
  }
}

export default useFadeInAnimation
