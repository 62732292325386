//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api'
import useExternalLink from '~/composables/useExternalLink'

export default defineComponent({
  name: 'Button',
  props: {
    label: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    buttonType: {
      type: String,
      default: 'primary-orange'
    },
    icon: {
      type: [String, Object],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    submit: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isInputButtonCombination: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { linkTarget, displayLink, isShopLink } = useExternalLink(props.link)
    const active = ref(false)
    const loadingDelay = ref(false)

    const buttonStyle = computed(() => {
      const styleByType = {
        'primary-orange': [
          active.value ? 'bg-orange-600' : 'gradient-primary-orange',
          'border-transparent focus:border-black text-black'
        ],
        'primary-white': [
          active.value ? 'bg-orange-600' : 'gradient-primary-white',
          'border-transparent focus:border-orange text-black'
        ],
        secondary: [
          active.value ? 'bg-orange-300' : 'gradient-secondary',
          'focus:bg-orange-100 border-orange text-black'
        ],
        transparent: []
      }

      if (showLoadingState.value) {
        return 'bg-orange-100 border-transparent text-grey pointer-events-none'
      }

      if (props.disabled) {
        return 'disabled:bg-grey-100 disabled:text-grey-300 disabled:border-grey-100'
      }

      return styleByType[props.buttonType]
    })

    const showLoadingState = computed(() =>
      props.isLoading || loadingDelay.value
    )

    const type = computed(() => {
      if (!props.link) {
        if (props.submit) {
          return 'submit'
        }
        return 'button'
      }
      return null
    })

    watch(() => props.isLoading, () => {
      if (props.isLoading) {
        loadingDelay.value = true

        setTimeout(() => {
          loadingDelay.value = false
        }, 500)
      }
    })

    return {
      buttonStyle,
      type,
      linkTarget,
      displayLink,
      showLoadingState,
      isShopLink
    }
  }
})
