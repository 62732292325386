var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[_c(_vm.link ? ((_vm.linkTarget === '_blank' || _vm.isShopLink) ? 'a' : 'NuxtLink') : 'button',{tag:"component",class:[
      _vm.isInputButtonCombination ? 'rounded-r' : 'rounded',
      _vm.buttonStyle,
      _vm.fullHeight ? 'h-full' : '',
      _vm.fullWidth ? 'w-full' : 'w-fit',
      {
        'btn': _vm.buttonType !== 'transparent',
        'cursor-pointer': !_vm.disabled
      } ],attrs:{"href":!_vm.link || _vm.link === '/' ? null : _vm.displayLink,"to":_vm.displayLink,"target":_vm.linkTarget,"type":_vm.type,"disabled":_vm.disabled || _vm.showLoadingState},on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.icon || (_vm.icon && _vm.icon.icon) || _vm.showLoadingState)?_c('Icon',{staticClass:"shrink-0",class:_vm.label ? 'w-4 h-4' : 'w-6 h-6',attrs:{"icon":_vm.showLoadingState ? 'IconLoader2' : _vm.icon || _vm.icon.icon,"show-animation":_vm.showLoadingState,"data-cy":"button-icon"}}):_vm._e(),_vm._v(" "),(_vm.label)?_c('span',[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._v(" "),_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }