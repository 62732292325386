const useReplaceRichtext = () => {
  function replaceRichtextTags (text, replaceRules = null) {
    let replacedText = text
    const rules = replaceRules || {
      'h[1-6]': {
        tag: 'p',
        class: null
      }
    }

    Object.keys(rules).forEach((rule) => {
      const regexpClosingTag = new RegExp(`</${rule}`, 'g')
      const newClosingTag = `</${rules[rule].tag}`

      const regexpOpenTag = new RegExp(`<${rule}`, 'g')
      const tagClass = rules[rule].class ? `class="${rules[rule].class}"` : ''
      const newOpeningTag = `<${rules[rule].tag} ${tagClass}`

      replacedText = replacedText
        .replace(regexpClosingTag, newClosingTag)
        .replace(regexpOpenTag, newOpeningTag)
    })

    return replacedText
  }

  return {
    replaceRichtextTags
  }
}
export default useReplaceRichtext
