import { render, staticRenderFns } from "./ImageText.vue?vue&type=template&id=ebda15ca&scoped=true&"
import script from "./ImageText.vue?vue&type=script&lang=js&"
export * from "./ImageText.vue?vue&type=script&lang=js&"
import style0 from "./ImageText.vue?vue&type=style&index=0&id=ebda15ca&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebda15ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ImageWrapper: require('/builds/reifen_gundlach/frontend/components/atoms/ImageWrapper/ImageWrapper.vue').default,RichText: require('/builds/reifen_gundlach/frontend/components/molecules/RichText/RichText.vue').default})
