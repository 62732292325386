import { useContext, computed } from '@nuxtjs/composition-api'
import useCurrentLanguage from '~/composables/useCurrentLanguage'

const useExternalLink = (link) => {
  const { bmfShopUrl, environment } = useContext().$config
  const externalLinks = ['http', 'www', 'mailto:']
  const { currentLanguage } = useCurrentLanguage()

  const displayLink = computed(() => {
    const regexSprache = /sprache=[\w]{0,2}/g
    const currentSpracheProp = `sprache=${currentLanguage.value}`
    let adjustedLink = link

    if (adjustedLink.includes(bmfShopUrl) || adjustedLink.includes('shop.best4tires')) {
      if ((environment === 'dev' || environment === 'qa')) {
        adjustedLink = adjustedLink.replace('shop.best4tires', `shop.${environment}.best4tires`)
      }

      if (link.match(regexSprache)) {
        return adjustedLink.replace(regexSprache, currentSpracheProp)
      } else {
        return `${adjustedLink}${adjustedLink.includes('?') ? '&' : '?'}${currentSpracheProp}`
      }
    }

    return adjustedLink
  })

  const linkTarget = computed(() => {
    if (!displayLink.value) { return '_self' }
    if (displayLink.value.startsWith(bmfShopUrl)) { return '_self' }
    if (externalLinks.some(externalLink => (displayLink.value.startsWith(externalLink)))) { return '_blank' }
    return '_self'
  })

  const isShopLink = computed(() => displayLink.value.startsWith(bmfShopUrl))

  return {
    linkTarget,
    displayLink,
    isShopLink
  }
}
export default useExternalLink
