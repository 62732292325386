var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.link ? ((_vm.linkTarget === '_blank' || _vm.isShopLink) ? 'a' : 'NuxtLink') : 'div',{tag:"component",staticClass:"link",class:[
    _vm.fullWidth ? 'w-full' : 'link-max-content-width',
    _vm.icon ? 'inline-flex' : '',
    _vm.iconPosition === 'left' ? 'flex-row' : 'flex-row-reverse',
    _vm.isDisabled ? 'pointer-events-none' : (!_vm.showUnderline ? '' : 'link-underline'),
    _vm.boldStyle ? 'font-bold' : 'font-normal'
  ],style:(_vm.cssVars),attrs:{"to":_vm.displayLink,"href":_vm.displayLink,"target":_vm.linkTarget},on:{"click":function($event){return _vm.$emit('click', $event)}}},[(_vm.icon)?_c('Icon',{staticClass:"shrink-0 w-4 h-4 link-item",class:[
      _vm.iconPosition === 'left' ? 'mr-2' : 'ml-2'
    ],attrs:{"icon":_vm.icon}}):_vm._e(),_vm._v("\n  "+_vm._s(_vm.label)+"\n  "),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }