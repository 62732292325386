//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, defineComponent } from '@nuxtjs/composition-api'
import useReplaceRichtext from '~/composables/useReplaceRichtext'

export default defineComponent({
  props: {
    text: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    primaryButton: {
      type: Array,
      default: null
    },
    secondaryButton: {
      type: Array,
      default: null
    },
    linkItem: {
      type: Array,
      default: null
    },
    maxWidth: {
      type: Number,
      default: 0
    }
  },
  setup (props) {
    const { replaceRichtextTags } = useReplaceRichtext()

    const hasButtons = computed(() => {
      return (
        props?.primaryButton?.length > 0 ||
        props?.secondaryButton?.length > 0 ||
        props?.linkItem?.length > 0
      )
    })

    const outputText = computed(() => {
      const replacedText = replaceRichtextTags(props.text)

      if (props.maxWidth > 0 && replacedText.length > props.maxWidth) {
        return `${replacedText.substring(0, props.maxWidth)}...`
      }
      return replacedText
    })

    return {
      hasButtons,
      outputText
    }
  }
})
